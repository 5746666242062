<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="row">
          <md-card :md-with-hover="true">
            <md-card-content>
              <div class="md-title">Account Details</div>
              <!-- <div class="md-subhead">{{ user.uid }}*****</div> -->

              <img
                class="avatar"
                :src="
                  this.user
                    ? this.user.profileImg
                    : 'https://secure.gravatar.com/avatar/0b7b3a96e4c10c2e403d355a59d5b6b0?s=500&d=identicon'
                "
                alt="Avatar"
              />
              <md-progress-spinner
                v-if="updatingProfilePhoto"
                :md-diameter="20"
                :md-stroke="3"
                md-mode="indeterminate"
              />
              <md-button
                v-if="!updatingProfilePhoto"
                class="md-icon-button"
                @click="
                  selectIdCard(
                    'Change Profile Image',
                    'this will replace your current profile image, Do you wish to proceed',
                    'public/profile',
                    onChangeProfilePhoto
                  )
                "
              >
                <md-icon>edit</md-icon>
              </md-button>


 <md-list class="md-double-line">
                <md-list-item>
 <md-icon class="md-primary">share</md-icon>
                  <div class="md-list-item-text">
                    <span>Referral Link</span>
                    <span>{{ "https://...hautestakes.com/register?r="+user.uid }}</span>
                  </div>
                    <md-button
                    @click="copy()"
                    class="md-button"
                  >
              <md-icon>file_copy</md-icon
                    >
                  </md-button>
                </md-list-item>

                <md-list-item>
                  <md-icon class="md-primary">account_circle</md-icon>

                  <div class="md-list-item-text">
                    <span>Names</span>
                    <span>{{ user.firstName }} {{ user.lastName }}</span>
                  </div>
                </md-list-item>

                <!-- <md-list-item>
                  <md-icon class="md-primary">verified_user</md-icon>

                  <div class="md-list-item-text">
                    <span>Account ID</span>
                    <span>{{ user.accountNumber }}</span>
                  </div>
                </md-list-item> -->



                <!-- EMAIL -->
                <md-list-item>
                  <md-icon class="md-primary">email</md-icon>

                  <div class="md-list-item-text">
                    <span>Email</span>
                    <span>{{ user.email }} </span>
                    <span v-if="user.isEmailVerified"> </span>
                  </div>
                  <md-button
                    @click="!user.isEmailVerified ? sendOtp() : () => {}"
                    class="md-icon-button"
                  >
                    <md-icon v-if="!user.isEmailVerified && !senOtpProgress"
                      >error_outline</md-icon
                    >
                    <md-icon v-if="user.isEmailVerified">verified</md-icon>
                    <md-progress-spinner
                      v-if="senOtpProgress"
                      :md-diameter="20"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    />
                  </md-button>
                </md-list-item>

                <!-- Two Factor Authtetication -->
                <md-list-item>
                  <md-icon class="md-primary">security</md-icon>

                  <div class="md-list-item-text">
                    <span>Two Factor Authentication (2FA)</span>
                    <span>{{ user.tfa ? "Enabled" : "Disabled" }} </span>
                  </div>

                  <toggle-button
                    v-model="user.tfa"
                    @change="onChangeTwoFactorAuth"
                  />

                  <md-progress-spinner
                    v-if="updateTwoFactorAuthProgress"
                    :md-diameter="20"
                    :md-stroke="3"
                    md-mode="indeterminate"
                  />
                </md-list-item>

                <!-- Verified User -->
                <md-list-item>
                  <md-icon class="md-primary">verified_user</md-icon>
                  <div class="md-list-item-text">
                    <span>Status </span>
                    <h1
                      v-bind:style="{
                        color:
                          user.verified === 'Verified'
                            ? 'lightgreen'
                            : 'yellow',
                      }"
                      v-text="user.verified || 'Not Verified'"
                    ></h1>
                    <h1 style="color: yellow" v-if="u">
                      {{ user.verified }}
                    </h1>
                  </div>
                  <div v-if="user.verified == 'Not Verified'">
                    <md-progress-spinner
                      v-if="uploadingIdProgress"
                      :md-diameter="20"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    />
                    <md-button
                      v-if="!uploadingIdProgress"
                      @click="
                        selectIdCard(
                          'Identity Verification',
                          'You will be required to upload a clear photo of your international passport, National ID card or a Drivers linces, Do you wish to proceed',
                          'public/verification',
                          identityVerification
                        )
                      "
                      class="md-icon-button"
                    >
                      <md-icon v-if="user.verified === 'Not Verified'"
                        >error_outline</md-icon
                      >
                      <md-icon v-if="user.verified === 'Verified'"
                        >verified</md-icon
                      >
                    </md-button>
                  </div>
                </md-list-item>

                <md-list-item>
                  <md-icon class="md-primary">login</md-icon>
                  <div class="md-list-item-text">
                    <span>Last Login</span>
                    <h1>{{ user.last_login }}</h1>
                  </div>
                </md-list-item>

              </md-list>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12" style="margin-top:20px">
        <md-card :md-with-hover="true">
          <md-card-header>
            <div class="md-title">Update Contact</div>
          </md-card-header>

          <md-card-content>
            <div>
              <!-- Telephone -->
              <md-field>
                <label>Telephone e.g: +1 234 567</label>

                <md-input v-model="$v.tel.$model"></md-input>
              </md-field>


            </div>
          </md-card-content>

          <md-card-actions>
            <md-progress-spinner
              v-if="updateProgress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button
              class="md-raised md-primary"
              style="color:#fff"
              v-if="!updateProgress"
              @click="updateAccount()"
              >Update</md-button
            >
          </md-card-actions>
        </md-card>
      </div>

      <!-- DIALOG BOX -->
      <div>
        <md-dialog :md-active.sync="showOtpDialog">
          <md-dialog-title
            >Verification code sent to {{ user.email }}</md-dialog-title
          >
          <div style="padding:20px">
            <md-field>
              <label>Enter otp code here:</label>
              <md-input v-model="otpCode" maxlength="6"></md-input>
            </md-field>
          </div>
          <md-dialog-actions>
            <md-progress-spinner
              v-if="verifyEmailProgress"
              :md-diameter="20"
              :md-stroke="3"
              md-mode="indeterminate"
            />
            <md-button class="md-primary" @click="showOtpDialog = false"
              >Close</md-button
            >
            <md-button
              class="md-primary"
              @click="verifyEmail"
              v-if="!verifyEmailProgress"
              >Verify</md-button
            >
          </md-dialog-actions>
        </md-dialog>
      </div>

      <!-- CONFIRMATION DIALOG -->
      <div>
        <md-dialog-confirm
          :md-active.sync="confirmDialog.active"
          :md-title="confirmDialog.title"
          :md-content="confirmDialog.content"
          md-confirm-text="Accept"
          md-cancel-text="Decline"
          @md-cancel="onCancel"
          @md-confirm="confirmDialog.action"
        />
      </div>

      <!-- Confirm Selectcd File For Upload -->
      <div>
        <md-dialog
          :md-active.sync="fileConfirmationDialog.active"
          :md-fullscreen="false"
          :md-click-outside-to-close="false"
        >
          <md-dialog-title
            >{{ fileConfirmationDialog.title }}
            <p>{{ fileConfirmationDialog.subtitle }}</p>
          </md-dialog-title>

          <p style="margin-left:20px">{{ fileConfirmationDialog.desc }}</p>

          <div v-html="fileConfirmationDialog.html"></div>
          <center>
            <md-dialog-actions>
              <md-button
                class="md-primary"
                @click="fileConfirmationDialog.active = false"
                >Cancel</md-button
              >
              <md-button
                class="md-primary"
                @click="fileConfirmationDialog.action"
                >Procced</md-button
              >
            </md-dialog-actions>
          </center>
        </md-dialog>
      </div>

      <!-- FIle Picker -->
      <input type="file" v-show="false" id="file" accept="image/*" />
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { isValidPhone } from "../../helpers/regex";
import Http from "../../helpers/http";
import { TRADERS, HOST } from "../../store/global";
import * as firebase from "firebase/app";

import "firebase/storage";
import UTILS from "../../helpers/utils";

firebase.initializeApp({
  apiKey: "AIzaSyA_36BggRWDnBo16lG2-OppiHOOS9E92Ek",
  authDomain: "hautestakes.firebaseapp.com",
  projectId: "hautestakes",
  storageBucket: "hautestakes.appspot.com",
  messagingSenderId: "329597779862",
  appId: "1:329597779862:web:35b891edb69bf85fdb27b7",
  measurementId: "G-3XCH45PMSQ"
});

const storage = firebase.storage().ref();

export default {
  name: "Profile",

  data() {
    return {
      user: this.$store.state.currentUser,
      showOtpDialog: false,
      verifyEmailProgress: false,
      senOtpProgress: false,
      updateProgress: false,
      updateTwoFactorAuthProgress: false,
      uploadingIdProgress: false,
      updatingProfilePhoto: false,
      file: null,
      otpCode: "",
      tel: this.$store.state.currentUser.tel,
      selectedCountry: null,
      selectedTrader: "",
      traders: TRADERS,
      fileConfirmationDialog: {
        title: "",
        subtitle: "",
        desc: "",
        html: ``,
        active: false,
        action: () => {},
      },
      confirmDialog: {
        active: false,
        title: "",
        content: "",
        result: "",
        action: () => {},
      },
      theme: null,
    };
  },
  mounted() {
    this.$store.dispatch("getUser", { token: "" });
  },
  created() {
    this.unsubcribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateTheme") {
        this.theme = state.theme === "dark";
      }
    });
  },
  beforeDestroy() {
    this.unsubcribe();
  },
  methods: {
    /** OnChage Two factor auth */
    async onChangeTwoFactorAuth(event) {
      const onFail = (err) => {
        this.user.tfa = !this.user.tfa;
        this.updateTwoFactorAuthProgress = false;
        console.log(err);
      };

      const onComplete = () => {
        this.updateTwoFactorAuthProgress = false;
      };

      try {
        const url = HOST + "/user/update";

        const body = {
          email: this.user.email,
          tfa: event.value,
        };

        this.updateTwoFactorAuthProgress = true;

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred updating two factor auth"
          );
        }
        this.showMsg(
          `Two Factor Authentication ${body.tfa ? "Enabled" : "Disabled"}`
        );

        this.$store.commit("setUser", req.data);
      } catch (error) {
        onFail(error);
      } finally {
        onComplete();
      }
    },

    /** On change profile photo */
    async onChangeProfilePhoto(imageUrl) {
      const onFail = (err) => {
        this.updatingProfilePhoto = false;
        console.log(err);
      };

      const onComplete = () => {
        this.user.profileImg = imageUrl;
        this.$store.commit("setUser", this.user);

        this.updatingProfilePhoto = false;
      };

      try {
        const url = HOST + "/user/update";

        const body = {
          email: this.user.email,
          profileImg: imageUrl,
        };

        this.updatingProfilePhoto = true;

        const req = await Http({ method: "POST", url, body });
        this.updatingProfilePhoto = false;

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred updating profile photo"
          );
        }
        this.showMsg(`Profile photo updated`);
      } catch (error) {
        onFail(error);
      } finally {
        onComplete();
      }
    },

    /**
     * Upload Blob to firebase
     */
    async uploadBlob(path, onComplete) {
      try {
        const fileName = this.user.email || UTILS.generateRef();
        const ref = storage.child(`${path}/${fileName}`);
        const upload = ref.putString(this.file, "data_url");
        await upload.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          () => {},
          console.log,
          async () => {
            const url = await upload.snapshot.ref.getDownloadURL();
            return await onComplete(url);
          }
        );
      } catch (err) {
        console.log(err);
        this.showMsg("Error occurred uploading document");
      }
    },
    /**
     * File Picker
     */
    selectIdCard(title, desc, path, onComplete) {
      if (this.user.Verified) return;

      this.showConfirmDialog(title, desc, path, async () => {
        this.uploadingIdProgress = true;

        document.getElementById("file").click();

        // On file seleted
        // Show file confirmation
        // Then register event on file change to execute final file upload confimation
        document.getElementById("file").addEventListener("change", (event) => {
          // validate file is selected
          if (!event.target.files || !event.target.files[0]) return;

          const reader = new FileReader();

          // on file read complete and call seleted file confirmation
          reader.onload = (blob) => {
            this.uploadingIdProgress = false;

            this.file = blob.target.result;

            this.selectedFileConfirmation({
              title: "Selected file for upload",
              subtitle: "Do you wish to proceed with upload",
              html: `<center><img
                class="avatar" width=300 height=300
                src='${blob.target.result}'
                alt="file upload"
              /> </center>`,
              action: async () => {
                // Upload ID
                try {
                  this.uploadingIdProgress = true;

                  await this.uploadBlob(path, onComplete);
                } catch (err) {
                  this.uploadingIdProgress = false;
                  console.log(err);
                }
              },
            });
          };
          // init read file
          reader.readAsDataURL(event.target.files[0]);
        });
      });
    },

    /**
     * Upload Id Card
     */
    async identityVerification(imageUrl) {
      const url = HOST + "/verification/id_card/init";

      const payload = { email: this.user.email, image: imageUrl };

      const req = await Http({ method: "POST", url, body: payload });
      if (!req.status) {
        this.uploadingIdProgress = false;
        return this.showMsg("An error occured while uploading verification");
      }
      this.showMsg("Verifcation under review");
      await this.$store.dispatch("getUser", { token: "" });
      this.uploadingIdProgress = false;
    },

    /**
     * Show Confirmation Dialog Handle
     */
    async showConfirmDialog(title, desc, arg, action) {
      this.confirmDialog.title = title;
      this.confirmDialog.content = desc;
      this.confirmDialog.action = async () => {
        await action(arg);
        this.confirmDialog.active = false;
      };
      this.confirmDialog.active = true;
    },
    /**
     * SEND OTP
     *
     */
    async sendOtp() {
      const url = HOST + "/otp/send";
      const body = { email: this.user.email };
      this.senOtpProgress = true;
      // send otp netowrk request
      const req = await Http({ url, method: "POST", body });
      this.senOtpProgress = false;

      if (!req.status) {
        return this.showMsg(
          req.message || "unale to send email verification code at the moment"
        );
      }
      // show success message
      this.showMsg("Verification code has been sent to your email address");

      // show otp input dialog
      this.showOtpDialog = true;
    },
    /**
     * Copy Referral Link
     */
    copy() {
      navigator.clipboard.writeText('https://'+window.location.host+'/register?r='+this.user.uid);
      this.showMsg("Copied !")
    },
    /**
     * Verify User Email Account
     */
    async verifyEmail() {
      if (this.otpCode.length < 6) return;
      try {
        const url = HOST + "/user/verify/email";

        const body = { code: this.otpCode, email: this.user.email };

        this.verifyEmailProgress = true;

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred verifying email address"
          );
        }

        this.$store.commit("setUser", req.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.verifyEmailProgress = false;
        this.showOtpDialog = false;
      }
    },

    /**
     * Show Dialog
     */
    async selectedFileConfirmation({
      title = "",
      subtitle = "",
      desc = "",
      html = "",
      action = () => {},
    }) {
      this.fileConfirmationDialog.title = title;
      this.fileConfirmationDialog.subtitle = subtitle;
      this.fileConfirmationDialog.desc = desc;
      this.fileConfirmationDialog.html = html;
      this.fileConfirmationDialog.action = async () => {
        this.fileConfirmationDialog.active = false;
        await action();
      };
      this.fileConfirmationDialog.active = true;
    },

    /**
     * Update Site Theme
     */
    setTheme() {
      this.$store.dispatch("setTheme", this.theme === true ? "dark" : "light");
    },
    /**
     * Show Snack Bar
     */
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },

    /**
     * Close snakbar
     */
    closeMsg() {
      this.$store.commit("showMsg", "");
    },

    /**
     * Update user tel and trader
     */
    async updateAccount() {
      try {
        const tel = "+" + this.tel;

        if (!this.tel || !isValidPhone.test(tel)) {
          return this.showMsg("Valid telephone number is required");
        }



        const url = HOST + "/user/update";

        const body = {
          email: this.user.email,
          tel: tel,
          trader: "-",
        };

        this.updateProgress = true;

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred updating account"
          );
        }
        this.showMsg("update successful");

        this.$store.commit("setUser", req.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.updateProgress = false;
      }
    },
  },
  validations: {
    tel: {
      required,
      minLenght: minLength(10),
    },

  },
};
</script>

<style lang="scss" scoped>
.avatar {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  text-align: start;
}
</style>
